<template>
  <div>
    <!-- 1.筛选条件 -->
    <div class="select-box">
      <div>
        <span>城市:</span>
        <el-select
          v-model="option.cityId"
          clearable
          placeholder="请选择城市"
          @change="getTabList"
        >
          <el-option
            v-for="item in data.cityListData"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div>
        <span style="width: 80px">订单编号:</span>
        <el-input
          v-model="option.orderNumber"
          clearable
          placeholder="请输入订单编号"
          style="width: 240px"
        ></el-input>
      </div>
      <div>
        <el-button @click="getTabList" icon="el-icon-search" type="primary"
          >查 询</el-button
        >
        <el-button @click="exportExcel()" icon="el-icon-download" type="primary"
          >导 出</el-button
        >
      </div>
    </div>
    <!--2. table表格的内容 data.table[option.page] -->
    <el-table border stripe :data="data.table" style="width: 100%">
      <el-table-column
        type="index"
        :index="indexMethod"
        width="50"
        style="text-align: center"
      >
      </el-table-column>
      <el-table-column
        prop="orderNumber"
        label="订单编号"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="shopName"
        label="门店名称"
        width="160"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="goodsName"
        label="商品名称"
        width="180"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column prop="memberID" label="会员编号" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="memberPhone" label="电话" width="110">
      </el-table-column>
      <el-table-column prop="price" label="单价"></el-table-column>
      <el-table-column prop="lowestPrice" label="最低价"></el-table-column>
      <el-table-column prop="quantity" label="数量"></el-table-column>
      <el-table-column prop="amount" label="订单金额"> </el-table-column>
      <el-table-column prop="creationTime" label="购买时间" width="170">
      </el-table-column>
      <el-table-column prop="payTime" label="付款时间" width="170">
      </el-table-column>
      <el-table-column prop="payStatus" label="付款状态"> </el-table-column>
      <el-table-column prop="isCancel" label="取消状态">
        <template #default="scope">
          {{ scope.row.isCancel ? "已取消" : "未取消" }}
        </template>
      </el-table-column>
      <el-table-column prop="channel" label="渠道"> </el-table-column>
      <el-table-column label="操作" width="200">
        <!--操作固定列 -->
        <template #default="scope">
          <el-button
            size="small"
            icon="el-icon-edit"
            @click="UpdateClick(scope.row)"
            type="primary"
            >查看</el-button
          >
          <el-button
            size="small"
            icon="el-icon-upload2"
            v-if="
              scope.row.isUpload == false && scope.row.payStatus == '支付成功'
            "
            @click="uploadOrder(scope.row)"
            type="primary"
            >重推</el-button
          >
          <el-button
            v-if="
              scope.row.payStatus == '待支付' && scope.row.isCancel == false
            "
            :loading="btnLoading"
            size="small"
            @click="syncPayStatus(scope.row)"
            type="text"
            >同步支付状态</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 隐藏的一个table，用于导出 -->
    <el-table
      v-show="false"
      id="table2"
      border
      stripe
      :data="data.table"
      style="width: 100%"
    >
      <el-table-column prop="id" label="ID" v-if="false"> </el-table-column>
      <!--隐藏列-->

      <!-- <el-table-column
        type="index" :index="indexMethod"
        width="50" style="text-align: center;">
      </el-table-column> -->
      <el-table-column prop="orderNumber" label="订单编号"> </el-table-column>
      <el-table-column prop="shopName" label="门店名称"> </el-table-column>
      <el-table-column prop="goodsName" label="商品名称"> </el-table-column>
      <el-table-column prop="memberID" label="会员编号"> </el-table-column>
      <el-table-column prop="memberPhone" label="电话"> </el-table-column>
      <el-table-column prop="lowestPrice" label="最低价"></el-table-column>
      <el-table-column prop="price" label="单价"></el-table-column>
      <el-table-column prop="quantity" label="数量"></el-table-column>
      <el-table-column prop="amount" label="订单金额"> </el-table-column>
      <el-table-column prop="creationTime" label="购买时间"> </el-table-column>
      <el-table-column prop="payTime" label="付款时间"> </el-table-column>
      <el-table-column prop="payStatus" label="付款状态"> </el-table-column>
      <el-table-column prop="channel" label="渠道"> </el-table-column>

      <!-- <el-table-column prop="semesterName" label="学期"> </el-table-column>
      <el-table-column prop="payStatu" :formatter="geshihua" label="支付状态"> </el-table-column>
      <el-table-column prop="totalAmount" label="总费用"> </el-table-column>
      <el-table-column prop="price" label="课时费"></el-table-column>
      <el-table-column prop="number" label="总课时"></el-table-column>

      <el-table-column prop="teacherName" label="老师名称"> </el-table-column>
      <el-table-column prop="schoolName" label="校区名称"> </el-table-column> -->
    </el-table>

    <!-- 3.分页 -->
    <el-pagination
      class="fenye"
      @current-change="handleCurrentChange"
      :current-page="option.page"
      :page-size="8"
      prev-text="&nbsp;&nbsp;&nbsp;上一页&nbsp;&nbsp;&nbsp;"
      next-text="&nbsp;&nbsp;&nbsp;下一页&nbsp;&nbsp;&nbsp;"
      background
      layout="total, prev, pager, next, jumper"
      :total="data.total"
    >
    </el-pagination>

    <!-- 4.更新操作 嵌套的表单  打开嵌套表单的 Dialog-->
    <el-dialog
      width="52%"
      title="订单详情"
      v-model="ruleForm.dialogFormVisible"
      :show-close="true"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleFormRef"
        label-width="150px"
        class="demo-ruleForm"
      >
        <el-form-item label="订单编号" prop="orderNumber" required>
          <el-input v-model="ruleForm.orderNumber"></el-input>
        </el-form-item>
        <el-form-item label="店铺编号" prop="shopID" required>
          <el-input v-model="ruleForm.shopID"></el-input>
        </el-form-item>
        <el-form-item label="店铺名称" prop="shopName" required>
          <el-input v-model="ruleForm.shopName"></el-input>
        </el-form-item>
        <el-form-item label="店铺电话" prop="shopPhone" required>
          <el-input v-model="ruleForm.shopPhone"></el-input>
        </el-form-item>
        <el-form-item label="商品编号" prop="goodsID" required>
          <el-input v-model="ruleForm.goodsID"></el-input>
        </el-form-item>
        <el-form-item label="商品名称" prop="goodsName" required>
          <el-input v-model="ruleForm.goodsName"></el-input>
        </el-form-item>
        <el-form-item label="商品类型" prop="goodsType" required>
          <el-input v-model="ruleForm.goodsType"></el-input>
        </el-form-item>
        <el-form-item label="商品汇总" prop="goodsSummary" required>
          <el-input v-model="ruleForm.goodsSummary"></el-input>
        </el-form-item>
        <el-form-item label="商品图片" prop="goodsListPic" required>
          <el-image
            style="width: 100px; height: 100px"
            :src="ruleForm.goodsListPic"
            :fit="fit"
          />
        </el-form-item>
        <el-form-item label="抵扣团单" prop="content">
          <el-input v-model="ruleForm.content"></el-input>
        </el-form-item>
        <!--        <el-form-item label="抵扣价" prop="deductionAmount" >
          <el-input v-model="ruleForm.deductionAmount"></el-input>
        </el-form-item>-->
        <el-form-item label="赠送项目" prop="giftItems">
          <el-input v-model="ruleForm.giftItems"></el-input>
        </el-form-item>
        <span class="demonstration">{{ fit }}</span>
        <el-form-item label="单价" prop="price" required>
          <el-input v-model="ruleForm.price"></el-input>
        </el-form-item>
        <el-form-item label="数量" prop="quantity" required>
          <el-input v-model="ruleForm.quantity"></el-input>
        </el-form-item>
        <el-form-item label="订单金额" prop="amount" required>
          <el-input v-model="ruleForm.amount"></el-input>
        </el-form-item>
        <el-form-item label="付款时间" prop="payTime" required>
          <el-input v-model="ruleForm.payTime"></el-input>
        </el-form-item>
        <el-form-item label="付款状态" prop="payStatu" required>
          <el-input v-model="ruleForm.payStatu"></el-input>
        </el-form-item>
        <el-form-item label="付款状态文本" prop="payStatusText" required>
          <el-input v-model="ruleForm.payStatusText"></el-input>
        </el-form-item>
        <el-form-item label="付款方式" prop="payType" required>
          <el-input v-model="ruleForm.payType"></el-input>
        </el-form-item>
        <el-form-item label="购买时间" prop="creationTime" required>
          <el-input v-model="ruleForm.creationTime"></el-input>
        </el-form-item>

        <!-- <el-form-item label="数据" prop="data" required>
            <el-input v-model="ruleForm.data.itemName"></el-input>
          </el-form-item> -->
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="resetForm('ruleForm')"
            >关 闭</el-button
          >
          <!-- <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button> -->
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<!-- 本页的js -->
<script>
import { reactive, ref } from "vue";
import {
  getorderlist,
  getorderdetail,
  getCityList,
  uploadOrderToZhu,
} from "../http/api";
import { syncOrderPayStatus } from "@/http/orderApi.js";
import { ElMessage } from "element-plus";
import { FullTimeFormat } from "../utils/common";
import { putToOSS, getFileNameUUID, getFileExtensions } from "@/http/oss";

//导出表格
import FileSaver from "file-saver";
import * as XLSX from "xlsx";

export default {
  name: "Order",
  setup() {
    const ruleFormRef = ref(null); //表单不能用this.$,取一个重名的
    //删除上传的图片
    let imageClose = () => {
      ruleForm.imageUrl = "";
      ruleForm.imageDiv = false;
    };
    //修改的表单参数//////////////////////////////////////////////////
    let ruleForm = reactive({
      dialogFormVisible: false,
      orderNumber: "",
      cityId: "",
      shopID: "",
      shopName: "",
      shopPhone: "",
      goodsID: "",
      goodsName: "",
      goodsSummary: "",
      goodsListPic: "",
      price: "",
      quantity: "",
      amount: "",
      payTime: "",
      payStatu: "",
      payStatusText: "",
      payType: "",
      creationTime: "",
      data: [],
      content: "",
      deductionAmount: "",
      giftItems: "",
    });

    // 按钮loading
    const btnLoading = ref(false);

    //点击新增按钮
    let AddClick = async () => {
      ruleForm.addOrUpdate = 0;
      ruleForm.dialogFormVisible = true; //显示出表单
      ruleForm.id = "";
      ruleForm.bannerTitle = "";
      ruleForm.DisplayDate = "";
      ruleForm.bannerSort = "";
      ruleForm.bannerMemo = "";
    };

    //点击修改按钮,弹框，默认把原来的数据显示出来
    let UpdateClick = async (obj) => {
      ruleForm.addOrUpdate = 1;
      ruleForm.id = obj.id;
      //赋值默认值弹框获取ID号，方便下一步进行新增及修改等
      let banner = await getorderdetail({ orderID: obj.orderID });
      console.log(ruleForm);
      console.log("banner", banner);

      ruleForm.orderNumber = banner.orderNumber;
      ruleForm.cityId = banner.cityID;
      ruleForm.shopID = banner.shopID;
      ruleForm.shopName = banner.shopName;
      ruleForm.shopPhone = banner.shopPhone;
      ruleForm.goodsID = banner.goodsID;
      ruleForm.goodsName = banner.goodsName;
      ruleForm.goodsSummary = banner.summary;
      ruleForm.goodsListPic = banner.goodsListPic;
      ruleForm.price = banner.price;
      ruleForm.quantity = banner.quantity;
      ruleForm.amount = banner.amount;
      ruleForm.payTime = FullTimeFormat(banner.payTime);
      ruleForm.payStatu = banner.payStatu;
      ruleForm.payStatusText = banner.payStatusText;
      ruleForm.payType = banner.payType;
      ruleForm.creationTime = FullTimeFormat(banner.creationTime);
      ruleForm.goodsType = banner.goodsType;
      ruleForm.giftItems = banner.giftItems;
      ruleForm.content = banner.content;
      ruleForm.deductionAmount = banner.deductionAmount;

      if (banner.payStatu == 0) {
        ruleForm.payStatu = "用户未支付";
      } else if (banner.payStatu == 1) {
        ruleForm.payStatu = "用户已支付";
      }

      if (banner.goodsType == "0") {
        //服务

        ruleForm.goodsType = "服务";
        if (banner.data0) {
          for (const fun of banner.data0) {
            ruleForm.data = fun;
          }
        }
      } else if (banner.goodsType == "1") {
        //产品

        ruleForm.goodsType = "产品";
      } else {
        //2 次卡
        ruleForm.goodsType = "次卡";

        if (banner.data2) {
          for (const fun of banner.data2) {
            ruleForm.data = fun;
          }
        }
      }

      ruleForm.dialogFormVisible = true; //显示出表单
    };

    //修改的表单验证
    let rules = reactive({});

    //提交表单,修改表单信息
    // let submitForm = async (formName) => {
    //   //console.log(formName);
    //   let con = false; //必须这样写，下面的return false是内部的方法不走了，但是下面还是执行的
    //   ruleFormRef.value.validate((valid) => {
    //     if (!valid) {
    //       con = true;
    //       console.log("下面的不执行了");
    //       return false;
    //     }
    //   });
    //   if (con) {
    //     return false;
    //   }

    //   if(ruleForm.addOrUpdate==1){ //更新
    //     let data={

    //       "orderID": data.orderID,

    //     }
    //     await getorderdetail(data);
    //   }
    //   queryOrderListFun(option);
    //   ruleFormRef.value.resetFields();
    //   //setTimeout(function () {
    //   ruleForm.dialogFormVisible = false;
    //   //}, 500);
    // };

    //表单重置
    let resetForm = (formName) => {
      //console.log(formName);
      //console.log( ruleFormRef.value);
      ///////ruleFormRef.value.resetFields();

      //setTimeout(function () {
      ruleForm.dialogFormVisible = false;
      //}, 500);
    };
    //声明数据
    let data = reactive({
      table: [],
      pages: 0,
      total: 0,
      cityListData: [],
      srcList: [""],
    });

    //声明查询条件
    let option = reactive({
      cityId: "",
      shopId: "",
      iscity: true,
      isshop: false,
      page: 1,
      pageSize: 8,
      orderNumber: null,
    });

    let indexMethod = (index) => {
      return index + (option.page - 1) * option.pageSize + 1;
    };

    let queryOrderListFun = async (obj) => {
      let arr = await getorderlist(obj);
      data.total = arr.totalCount;
      //格式化日期
      arr.items.filter((item) => {
        item.creationTime = FullTimeFormat(item.creationTime);
        item.payTime = FullTimeFormat(item.payTime);
      });
      data.table = arr.items;
    };
    //获取城市列表方法
    let getCityListDataFun = async (obj) => {
      let cityList = await getCityList(obj);
      data.cityListData = cityList;
    };
    //初始化时，获取初始化数据
    queryOrderListFun(option);
    getCityListDataFun({});
    //点击页码获取数据
    let handleCurrentChange = (index) => {
      //接口是接收的guid,这样写不报错
      if (option.cityId == "") {
        option.cityId = null;
      }
      if (option.shopId == "") {
        option.shopId = null;
      }
      option.page = index;
      queryOrderListFun(option);
    };
    //查询方法,此处需要根据条件到后台进行筛选
    let getTabList = async () => {
      if (option.cityId == "") {
        option.cityId = null;
      }
      if (option.shopId == "") {
        option.shopId = null;
      }
      queryOrderListFun(option);
    };
    //大图
    let bigImg = (url) => {
      data.srcList[0] = url;
    };
    let beforeUpload = (file) => {
      // 限制上传类型
      const fileExtensions =
        getFileExtensions(file.name) === ".jpg" ||
        getFileExtensions(file.name) === ".png" ||
        getFileExtensions(file.name) === ".jpeg";
      //限制的上限为2M
      const max2M = file.size / 1024 / 1024 < 2;
      if (!fileExtensions) {
        ElMessage.error({
          message: "上传文件类型只能是 .jpg, .png, .jpeg 格式!",
          duration: 2000,
        });
      }
      if (!max2M) {
        ElMessage.error({
          message: "上传文件大小不能超过 2MB!",
          duration: 2000,
        });
      }
      return fileExtensions && max2M;
    };
    let handleUpload = (option) => {
      // 生成的文件名，保留文件后缀名，进行拼接
      let objName =
        "GoodsImg/" + getFileNameUUID() + getFileExtensions(option.file.name);
      // 调用 ali-oss 中的方法,flieName是存放的文件夹名称，可自己定义
      putToOSS(`flieName/${objName}`, option.file).then((res) => {
        //console.log(res,'res')
        // 上传成功之后，转换真实的地址
        //signatureUrl(`flieName/${objName}`).then(res => {
        //console.log(res)
        ruleForm.imageUrl = res.url;
        ruleForm.imageDiv = true;
        //})
      });
    };
    let submitUpload = () => {
      this.$refs.upload.submit();
    };
    let handleRemove = (file, fileList) => {
      console.log(file, fileList);
    };
    let handlePreview = (file) => {
      console.log(file);
    };

    //导出表格为excel
    let exportExcel = () => {
      //导出前，先获取所有数据
      exportData(data.total);
      //开始导出
      setTimeout(exportExcel2, 500);
    };

    //获取多少条数据方法
    let exportData = (total) => {
      option.page = 1;
      option.pageSize = total;
      queryOrderListFun(option);
    };
    //导出方法
    let exportExcel2 = () => {
      var xlsxParam = { raw: true }; // 导出的内容只做解析，不进行格式转换(添加此行代码表格中的百分比就不会再导出的时候被转换成小数点)
      let wb = XLSX.utils.table_to_book(
        document.querySelector("#table2"),
        xlsxParam
      );
      let wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array",
      });
      // 获取当前的时间戳，用来当文件名
      let time = new Date().getTime();
      try {
        FileSaver.saveAs(
          new Blob([wbout], { type: "application/octet-stream" }),
          `${time}.xlsx`
        ); //${time}是自己导出文件时的命名，随意
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, wbout);
      }
      //导出后数据还原
      exportData(8);
      return wbout;
    };

    //重推订单到主业务系统
    let uploadOrder = async (obj) => {
      let params = {
        orderID: obj.orderID,
      };
      let res = await uploadOrderToZhu(params);
      console.log(res);
      const { code, message } = res;
      if (code == 0) {
        ElMessage.success("重推成功");
        getTabList();
      } else {
        ElMessage.error(message || "重推失败！请重试或联系管理员");
      }
    };

    /** 同步支付结果 */
    const syncPayStatus = async (row) => {
      btnLoading.value = true;
      try {
        const { code, message } = await syncOrderPayStatus(row.orderID);
        if (code != 0 && code != 200) {
          ElMessage.error(message || "同步失败");
          return;
        }
        ElMessage.success(message || "同步成功");
        getTabList();
      } catch (e) {
        ElMessage.error(e || "同步失败");
      } finally {
        btnLoading.value = false;
      }
    };

    return {
      data,
      handleCurrentChange,
      getTabList,
      option,
      btnLoading,
      UpdateClick,
      ruleForm,
      ruleFormRef,
      rules,
      // submitForm,
      resetForm,
      AddClick,
      imageClose,
      getFileExtensions,
      beforeUpload,
      handleUpload,
      submitUpload,
      handleRemove,
      handlePreview,
      bigImg,
      uploadOrder,
      indexMethod,
      exportExcel,
      exportData,
      syncPayStatus,
    };
  },
};
</script>

<!-- 本页的css -->
<style lang="scss" scoped>
.box {
}
.el-image-viewer__close {
  top: 40px;
  right: 40px;
  width: 40px;
  height: 40px;
  font-size: 24px;
  color: #fff;
  background-color: rgba(255, 255, 255, 0) !important;
}
.select-box {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
  > div {
    // width: 15%;
    display: flex;
    align-items: center;
    span {
      // width: 50px;
    }
    margin-right: 25px;
  }
}
.fenye {
  margin-top: 20px;
}
</style>
